import React from 'react';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { useIntl } from 'react-intl';

// Components
import Container from '../../../../components/Container';
import Heading from '../../../../components/Heading/DesktopView';
import Button from '../../../../components/Button';

// State
import { hideFreeSaucesPanel } from '../../../../state/modules/freeSauces/actions';
import { avaivableCountSelector } from '../../../../state/modules/freeSauces/selectors';

// Partials
import useFreeSaucesPanel from '../useFreeSaucesPanel';

// Icons
import GiftIcon from '../../../../icons/gift.svg';

// Styles
import styles from './FreeSaucesPanel.styl';

const cx = classNames.bind(styles);

const duration = 350;

const defaultStyle = {
  transform: 'translateY(100%)',
  transition: `transform ${duration}ms`,
};

const transitionStyles = {
  entering: {
    transform: 'translateY(100%)',
  },
  entered: {
    transform: 'translateY(0%)',
  },
};

const overlayDefaultStyle = {
  opacity: 0,
  transition: `opacity ${duration}ms`,
};

const overlayTransitionStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
};

export default function FreeSaucesPanel() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { panelOpened, list } = useSelector(state => state.freeSauces);
  const avaivableCount = useSelector(avaivableCountSelector);

  const onHidePanel = () => {
    dispatch(hideFreeSaucesPanel());
  };

  const { onAdd, isAdding } = useFreeSaucesPanel();

  return (
    <div className={cx('FreeSaucesPanel')} data-test-id='free_sauces_panel'>
      <Transition in={panelOpened} timeout={duration} mountOnEnter unmountOnExit>
        {state => (
          <>
            <button
              onClick={onHidePanel}
              className={cx('FreeSaucesPanel__overlay')}
              type="button"
              style={{
                ...overlayDefaultStyle,
                ...overlayTransitionStyles[state],
              }}
            />
            <div
              className={cx('FreeSaucesPanel__panel')}
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Container>
                <Heading className={cx('FreeSaucesPanel__title')} level={3} tagName="div">
                  {intl.formatMessage({ id: 'freeSauces.panel.title' }, { count: avaivableCount })}
                </Heading>
                <div className={cx('FreeSaucesPanel__list-wrapper')}>
                  <div className={cx('FreeSaucesPanel__list')}>
                    {list.map(sauce => {
                      const { name, variations, alias } = sauce;

                      const { id, image_list, size } = variations[0];

                      return (
                        <div className={cx('Sauce')} key={alias}>
                          <figure className={cx('Sauce__image')}>
                            <img src={image_list} alt={name} />
                          </figure>
                          <div className={cx('Sauce__content')}>
                            <div className={cx('Sauce__info')} data-test-id='sauce_info'>
                              <Heading className={cx('Sauce__name')}>{name}</Heading>
                              <div className={cx('Sauce__note')}>
                                {size?.value}&nbsp;{size?.unit}
                              </div>
                            </div>
                            <div className={cx('Sauce__footer')}>
                              <Button
                                className={cx('Sauce__button')}
                                onClick={() => onAdd(id)}
                                isLoading={isAdding === id}
                                disabled={isAdding}
                                small
                                data-test-id='sauce_button'
                              >
                                <GiftIcon />
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Container>
            </div>
          </>
        )}
      </Transition>
    </div>
  );
}
